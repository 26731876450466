document.addEventListener('DOMContentLoaded', function () {
  // thumb_byが設定されたlabel
  const toggles = document.querySelectorAll(
    "label[data-thumb-by]"
  );
  const fileForm = document.querySelector('.thumb-form')

  toggles.forEach(function (toggle) {
    toggle.addEventListener('click', function (event) {
      const needFileForm = (event.target.dataset.thumbBy == 'assign')

      fileForm.style.display = needFileForm ? 'block' : 'none'
      fileForm.disabled = !needFileForm
    });
  });
});

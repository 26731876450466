// prevent html validation error after the field is removed by cocoon
// error: An invalid form control with name='xxx' is not focusable.
document.addEventListener('DOMContentLoaded', () => {
  document.addEventListener('cocoon:after-remove', (event) => {
    // select hidden required elements
    event.target
      .querySelectorAll('.nested-fields[style*="display: none"]')
      .forEach((nestedField) => {
        nestedField.querySelectorAll('[required]').forEach((it) => {
          it.required = false;
        });
      });
  });
});

require('@rails/ujs').start();

import 'bootstrap/dist/css/bootstrap';
import 'admin-lte/dist/css/AdminLTE';
import 'admin-lte/dist/css/skins/skin-red';
import '../styles/biz/main';

import 'bootstrap/dist/js/bootstrap';
import 'admin-lte/dist/js/adminlte';

// cocoon
import '@oddcamp/cocoon-vanilla-js';
import './cocoon_assistance';

import '../scripts/biz/copy';
import '../scripts/biz/dashboard';
import '../scripts/biz/thumb_form_switcher';
import '../scripts/biz/rich_text_editor';
import './choices_select';
import './thumb_loader';

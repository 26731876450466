import { createRichTextsAttachmentImage } from 'scripts/biz/api'

import tinymce from 'tinymce/tinymce';

// for webpack
import 'tinymce/icons/default';
import 'tinymce/themes/silver';

// plugins
import 'tinymce/plugins/image';
import 'tinymce-i18n/langs5/ja';

document.addEventListener('DOMContentLoaded', () => {
  const editor_style = require('!css-loader!sass-loader!styles/biz/rich_text_preview')

  tinymce.init({
    selector: ".rich-text-editor",
    body_class: 'pharmaceutical__description pharmaceutical',
    content_style: editor_style.toString(),
    language: 'ja',
    menubar: false,
    toolbar: "undo redo | formatselect | fontsizeselect | bold italic | image",
    block_formats: 'Paragraph=p;Header 2=h2;Header 3=h3',
    plugins: [ "image" ],
    image_title: true,
    image_description: false,
    file_picker_callback: file_picker_callback,
  })
})

function file_picker_callback(callback, value, meta) {
  let input = document.createElement('input')
  input.setAttribute('type', 'file')
  input.setAttribute('accept', 'image/*')

  input.onchange = function () {
    let image = this.files[0];

    let formData = new FormData();
    formData.append('image[content]', image);

    let reader = new FileReader();
    reader.onload = function () {
      createRichTextsAttachmentImage(image)
        .then(response => {
          const path = require('path');
          let image = response.content

          callback(image.url, { title: path.basename(image.url) });
        })
    };
    reader.readAsDataURL(image);
  };

  input.click();
}

import Chart from 'chart.js';
import 'chartjs-plugin-colorschemes';
import 'chartjs-plugin-labels';

function drawDoughnutChart(elementId, data) {
  const canvas = document.getElementById(elementId);
  const context = canvas.getContext('2d');
  const options = {
    // 凡例を表の右側に表示、開始位置揃えに
    legend: { position: 'right', align: 'start' },
    // [chartjs-plugin-labels] %表示
    labels: [{ render: 'percentage' }],
  };
  correctCanvasSize(context);
  new Chart(context, { type: 'doughnut', data: data, options: options });
}

function drawBarChart(elementId, data) {
  const canvas = document.getElementById(elementId);
  const context = canvas.getContext('2d');
  const options = {
    // DL数等の目盛が小数にならないよう、stepSizeに1を指定
    // 0件が続く時にy軸最小値に-1が出現しないよう、最小値に0を指定
    scales: { yAxes: [{ ticks: { stepSize: 1, min: 0 } }] },
    // for chartjs-plugin-labels: 値をバーの上に表示する
    plugins: { labels: { render: 'value' } },
  };
  correctCanvasSize(context);
  new Chart(context, { type: 'bar', data: data, options: options });
}

function correctCanvasSize(context) {
  // canvasのサイズが設定値と実サイズでズレが出るため補正する
  context.canvas.height = context.canvas.clientHeight;
  context.canvas.width = context.canvas.clientWidth;
}

document.addEventListener('DOMContentLoaded', () => {
  // 目盛り線(x, y)を非表示にする
  // Chart.defaults.scale.gridLines.display = false;
  document.querySelectorAll('canvas.chart-canvas').forEach((element) => {
    drawBarChart(element.id, JSON.parse(element.dataset.json));
  });
  document.querySelectorAll('canvas.doughnut-canvas').forEach((element) => {
    drawDoughnutChart(element.id, JSON.parse(element.dataset.json));
  });
});

import { csrfToken } from '@rails/ujs';

export {
  createRichTextsAttachmentImage
}

function createRichTextsAttachmentImage(image) {
  let url = '/api/rich_texts/attachment_image.json'

  let formData = new FormData()
  formData.append('image[content]', image)

  let options = {
    method: 'POST',
    body: formData,
  }

  return sendRequest(url, options)
}

function sendRequest(url, options) {
  options = Object.assign({
    headers: {
      'X-CSRF-Token': csrfToken(),
    }
  }, options)

  return fetch(url, options).then(response => {
    if (!response.ok) {
      throw new Error(`request faild. status: ${response.status}`)
    }

    return response.json()
  }).then(json => json.result)
}

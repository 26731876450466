import Choices from 'choices.js';
import 'choices.js/public/assets/styles/choices.min';

const choicesOptions = {
  shouldSort: false,
  removeItemButton: true,
  searchResultLimit: 8,
  searchFields: ['label'],
  searchPlaceholderValue: '検索',
  itemSelectText: '選択',
  placeholderValue: '選択してください',
  noResultsText: '該当する項目が見つかりませんでした。',
  noChoicesText: '選択項目がありません。',
};

function applyChoices() {
  /* apply choices to select boxes */
  const selects = document.querySelectorAll('select');
  selects.forEach((select) => {
    // 既にchoicesが適用済ならスキップ
    if (select.choices) return;
    select.choices = new Choices(select, choicesOptions);
  });
}

document.addEventListener('DOMContentLoaded', () => {
  applyChoices();
});

document.addEventListener('cocoon:after-insert', () => {
  applyChoices();
});
